<template>
  <VxDataListItem>
    <template #desktop>
      <td>{{ item.bin.name }}</td>
      <td>{{ item.notes }}</td>
      <td>{{ item.category.name }}</td>
      <td>{{ item.location.name }}</td>
      <td>{{ formattedDate }}</td>
      <td>{{ formattedUpdateTime }}</td>
      <td>
        <EmployeeAvatar :employee-id="item.employeeId" />
      </td>
    </template>
    <template #mobile>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>
            {{ item.bin.name }}
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-content>
          <v-list-item-title>
            {{ item.category.name }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ item.location.name }}
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action-text>
          {{ formattedUpdateTime }}
        </v-list-item-action-text>
      </v-list-item>
    </template>
  </VxDataListItem>
</template>

<script>
import { VxDataListItem } from "@/core-ui";
import EmployeeAvatar from "@/components/employees/Avatar.vue";

import { format, parseISO } from "date-fns";
export default {
  name: "BackstockHistoryListItem",
  components: { VxDataListItem, EmployeeAvatar },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    formattedDate() {
      return format(parseISO(this.item.invDate), "MM/dd/yy");
    },
    formattedUpdateTime() {
      return format(parseISO(this.item.updatedAt), "MMM dd, yyyy, HH:mm");
    },
  },
};
</script>
