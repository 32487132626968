<template>
  <VxDataList
    :headers="headers"
    :pagination-options="paginationOptions"
    :sort-options="sortOptions"
    :loading="historyLoading"
    :data-loading-text="loadingText"
    :empty-data-text="emptyText"
    @update:page="onPageUpdate"
    @update:itemsPerPage="onItemsPerPageUpdate"
    @update:sort="onSortUpdate"
    @showMore="onShowMore"
  >
    <template #body>
      <BackstockHistoryListItem
        v-for="item in backstockHistories"
        :key="item.id"
        :item="item"
      />
    </template>
  </VxDataList>
</template>

<script>
import BackstockHistoryListItem from "./components/BackstockHistoryListItem.vue";
import { BACKSTOCK_HISTORY_QUERY } from "./graphql/";
import {
  VxDataList,
  PaginationOptionsUtils,
  ServerPaginationUtils,
} from "@/core-ui";

export default {
  name: "BackstockHistoryListView",
  components: { VxDataList, BackstockHistoryListItem },
  props: {
    storeId: {
      type: [Number, String],
      required: true,
    },
    parsedFilter: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    const defaultPaginationOptions =
      PaginationOptionsUtils.buildDefaultPaginationOptions();

    const defaultSortOptions = PaginationOptionsUtils.buildDefaultSortOptions({
      sortBy: "updatedAt",
      sortOrder: "desc",
    });

    const defaultServerPaginationRequest =
      PaginationOptionsUtils.buildDefaultServerPaginationRequest(
        defaultPaginationOptions,
        defaultSortOptions
      );

    return {
      backstockHistories: [],
      historyLoading: 0,
      paginationOptions: defaultPaginationOptions,
      sortOptions: defaultSortOptions,
      serverPaginationRequest: defaultServerPaginationRequest,
    };
  },
  apollo: {
    backstockHistories: {
      query: BACKSTOCK_HISTORY_QUERY,
      loadingKey: "historyLoading",
      variables() {
        return this.queryVariables;
      },
      skip() {
        return !this.storeId;
      },
      update({ backstockHistories = { edges: [] } }) {
        ServerPaginationUtils.updateOptionsFromServerData(
          this.paginationOptions,
          backstockHistories
        );

        return backstockHistories.edges?.map(({ node }) => node);
      },
    },
  },
  computed: {
    headers() {
      return [
        {
          propertyPath: "bin.name",
          label: this.$t("report.app.backstockHistory.headers.binNumber"),
          sortable: true,
          cssClass: "binNumber-cell",
        },
        {
          propertyPath: "notes",
          label: this.$t("report.app.backstockHistory.headers.notes"),
          sortable: true,
          cssClass: "notes-cell",
        },
        {
          propertyPath: "category.name",
          label: this.$t("report.app.backstockHistory.headers.category"),
          sortable: true,
          cssClass: "category-cell",
        },
        {
          propertyPath: "location.name",
          label: this.$t("report.app.backstockHistory.headers.location"),
          sortable: true,
          cssClass: "location-cell",
        },
        {
          propertyPath: "invDate",
          label: this.$t("report.app.backstockHistory.headers.binAge"),
          sortable: true,
          cssClass: "binAge-cell",
        },
        {
          propertyPath: "updatedAt",
          label: this.$t("report.app.backstockHistory.headers.updatedAt"),
          sortable: true,
          cssClass: "updatedAt-cell",
        },
        {
          propertyPath: "employeeId",
          label: this.$t("report.app.backstockHistory.headers.employee"),
          sortable: false,
          cssClass: "employee-cell",
        },
      ];
    },
    queryVariables() {
      return {
        storeId: this.storeId,
        ...this.serverPaginationRequest,
        filter: this.parsedFilter.GQLdata,
      };
    },
    loadingText() {
      return this.$t("report.app.backstockHistory.loadingText");
    },
    emptyText() {
      return this.$t("report.app.backstockHistory.emptyText");
    },
  },
  watch: {
    filter(newFilterValue) {
      this.onPageUpdate(1);
    },
  },
  methods: {
    onShowMore() {
      if (this.isSmallScreen) {
        let mobileServerPaginationRequest = {};
        ServerPaginationUtils.updatePage(
          this.paginationOptions,
          this.sortOptions,
          mobileServerPaginationRequest,
          this.paginationOptions.page + 1
        );
        ServerPaginationUtils.fetchMoreData(
          this.$apollo.queries,
          "backstockHistories",
          "backstockHistories",
          {
            ...this.queryVariables,
            ...mobileServerPaginationRequest,
          }
        );
      }
    },
    onPageUpdate(page) {
      ServerPaginationUtils.updatePage(
        this.paginationOptions,
        this.sortOptions,
        this.serverPaginationRequest,
        page
      );
    },
    onItemsPerPageUpdate(itemsPerPage) {
      ServerPaginationUtils.updateItemsPerPage(
        this.paginationOptions,
        this.sortOptions,
        this.serverPaginationRequest,
        itemsPerPage
      );
    },
    onSortUpdate(header) {
      ServerPaginationUtils.updateSort(
        this.paginationOptions,
        this.sortOptions,
        this.serverPaginationRequest,
        header.propertyPath
      );
    },
  },
};
</script>
